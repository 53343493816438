import { IPlan } from "@/types";
import dummyPlans from "../../data/plans.json";
import axios from "axios";

const isOffline = process.env.VUE_APP_IS_OFFLINE === "true";
const domain = process.env.VUE_APP_API_DOMAIN;

const baseUrl = `https://api.${domain}`;

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  }
});

async function getMembersipPlans(): Promise<IPlan[]> {
  if (isOffline) return dummyPlans;
  return (await axiosInstance.get<{ data: { plans: IPlan[] } }>("/users/plans")).data.data.plans;
}

async function getRecentUsers(): Promise<string[]> {
  return (await axiosInstance.get("/users/recent")).data.users;
}

export default {
  getMembersipPlans,
  getRecentUsers,
}